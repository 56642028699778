<template>
  <div class="system-board">
    <el-row :gutter="20" type="flex">
      <el-col :span="6"
        ><div class="top-item">
          <img class="top-left" src="~@/static/images/yundan.png" alt="" />
          <div class="top-right">
            <div class="right-title">运单总数</div>
            <div class="right-count">
              <el-statistic
                suffix="单"
                :value="responseData.totalTaskBillCount"
              ></el-statistic>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :span="6"
        ><div class="top-item">
          <img class="top-left" src="~@/static/images/zhangdan.png" alt="" />
          <div class="top-right">
            <div class="right-title">今日放款总笔数</div>
            <div class="right-count">
              <el-statistic
                suffix="笔"
                :value="responseData.todayLoanCount"
              ></el-statistic>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6"
        ><div class="top-item">
          <img class="top-left" src="~@/static/images/zhifu.png" alt="" />
          <div class="top-right">
            <div class="right-title">今日放贷总金额</div>
            <div class="right-count">
              <el-statistic
                prefix="￥"
                group-separator=","
                :precision="2"
                :value="responseData.todayLoanAmount"
              ></el-statistic>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6"
        ><div class="top-item">
          <img class="top-left" src="~@/static/images/tongji.png" alt="" />
          <div class="top-right">
            <div class="right-title">近7天放贷总金额</div>
            <div class="right-count">
              <el-statistic
                prefix="￥"
                group-separator=","
                :precision="2"
                :value="responseData.last7DaysLoanAmount"
              ></el-statistic>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6"
        ><div class="top-item">
          <img class="top-left" src="~@/static/images/tongji.png" alt="" />
          <div class="top-right">
            <div class="right-title">近30天放贷总金额</div>
            <div class="right-count">
              <el-statistic
                prefix="￥"
                group-separator=","
                :precision="2"
                :value="responseData.last30DaysLoanAmount"
              ></el-statistic>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="box">
      <div class="box-title">借贷订单统计</div>
      <div class="box-content">
        <el-descriptions>
          <el-descriptions-item label="待初审订单"
            >({{
              responseData.ordersPendingInitialReview
            }})</el-descriptions-item
          >
          <el-descriptions-item label="已结清订单"
            >({{ responseData.ordersSettled }})</el-descriptions-item
          >
          <el-descriptions-item label="初审驳回订单"
            >({{
              responseData.ordersInitialReviewRejected
            }})</el-descriptions-item
          >
          <el-descriptions-item label="待复审订单"
            >({{ responseData.ordersPendingReReview }})</el-descriptions-item
          >
          <el-descriptions-item label="已逾期订单"
            >({{ responseData.ordersOverdue }})</el-descriptions-item
          >
          <el-descriptions-item label="复审驳回订单"
            >({{
              responseData.ordersRejectedSecondReview
            }})</el-descriptions-item
          >
          <el-descriptions-item label="待放款订单"
            >({{ responseData.ordersPendingLoan }})</el-descriptions-item
          >
          <el-descriptions-item label="已展期订单"
            >({{ responseData.ordersExtended }})</el-descriptions-item
          >
          <el-descriptions-item label="放款失败订单"
            >({{ responseData.loanFailedOrders }})</el-descriptions-item
          >
        </el-descriptions>
      </div>
    </div>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="box">
          <div class="box-title">借贷订单统计</div>
          <div class="box-content">
            <el-row>
              <el-col :span="6">
                <div class="middle-count">
                  {{ responseData.totalLoanCount }}
                </div>
                <div class="middle-title">在贷总笔数</div>
              </el-col>
              <el-col :span="6">
                <div class="middle-count">
                  {{ responseData.tomorrowRepaymentCount }}
                </div>
                <div class="middle-title">明天待还笔数</div>
              </el-col>
              <el-col :span="6">
                <div class="middle-count">
                  <el-statistic
                    prefix="￥"
                    group-separator=","
                    :precision="2"
                    :value="responseData.tomorrowRepaymentAmount"
                  ></el-statistic>
                </div>
                <div class="middle-title">明天待还总金额</div>
              </el-col>
              <el-col :span="6">
                <div class="middle-count">
                  <el-statistic
                    prefix="￥"
                    group-separator=","
                    :precision="2"
                    :value="responseData.totalPlatformEarnings"
                  ></el-statistic>
                </div>
                <div class="middle-title">平台总收益</div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="box">
          <div class="box-title">供应商总览</div>
          <div class="box-content">
            <el-row>
              <el-col :span="6">
                <div class="middle-count">
                  {{
                    responseData.getSuppliersNumStatisticsInfo.todayNewCount
                  }}
                </div>
                <div class="middle-title">今日新增</div>
              </el-col>
              <el-col :span="6">
                <div class="middle-count">
                  {{
                    responseData.getSuppliersNumStatisticsInfo
                      .yesterdayNewCount
                  }}
                </div>
                <div class="middle-title">昨日新增</div>
              </el-col>
              <el-col :span="6">
                <div class="middle-count">
                  {{
                    responseData.getSuppliersNumStatisticsInfo
                      .thisMonthNewCount
                  }}
                </div>
                <div class="middle-title">本月新增</div>
              </el-col>
              <el-col :span="6">
                <div class="middle-count">
                  {{ responseData.getSuppliersNumStatisticsInfo.totalCount }}
                </div>
                <div class="middle-title">供应商总数</div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="box">
          <div class="box-title2">
            <span>按产品统计</span>
            <el-radio-group
              v-model="radio"
              size="small"
              @change="productChartChange"
            >
              <el-radio-button label="1">近一周</el-radio-button>
              <el-radio-button label="2">近14天</el-radio-button>
              <el-radio-button label="3">近一月</el-radio-button>
              <el-radio-button label="4">近一年</el-radio-button>
            </el-radio-group>
          </div>
          <!-- <div class="y-axis-title">借贷成功数</div> -->
          <div id="product" class="box-content"></div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="box">
          <div class="box-title2">
            <span>按核心企业统计</span>
            <el-radio-group
              v-model="radio2"
              size="small"
              @change="enterpriseChartChange"
            >
              <el-radio-button label="1">近一周</el-radio-button>
              <el-radio-button label="2">近14天</el-radio-button>
              <el-radio-button label="3">近一月</el-radio-button>
              <el-radio-button label="4">近一年</el-radio-button>
            </el-radio-group>
          </div>
          <!-- <div class="y-axis-title">借贷成功数</div> -->
          <div id="enterprise" class="box-content"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import echarts from 'echarts';
import '@/plugins/axios';
export default {
  data() {
    return {
      radio: '4',
      radio2: '4',
      productChart: null,
      enterpriseChart: null,
      responseData: {
        getSuppliersNumStatisticsInfo:{}
      },
    }
  },
  mounted() {
    this.getData()
  },
  beforeDestroy() {
    this.productChart.dispose()
    this.enterpriseChart.dispose()
  },
  methods: {
    getData() {
      const requestList = [
        // 今日放款总笔数
        '/statistics/todayLoanCount',
        // 今日放款总金额
        '/statistics/todayLoanAmount',
        // 近7天放款总金额
        '/statistics/last7DaysLoanAmount',
        // 近30天放款总金额
        '/statistics/last30DaysLoanAmount',
        // 待初审订单
        '/statistics/ordersPendingInitialReview',
        // 待复审订单
        '/statistics/ordersPendingReReview',
        // 待放款订单
        '/statistics/ordersPendingLoan',
        // 已结清订单
        '/statistics/ordersSettled',
        // 已逾期订单
        '/statistics/ordersOverdue',
        // 已展期订单
        '/statistics/ordersExtended',
        // 初审驳回订单
        '/statistics/ordersInitialReviewRejected',
        // 复审驳回订单
        '/statistics/ordersRejectedSecondReview',
        // 放款失败订单
        '/statistics/loanFailedOrders',
        // 在贷总笔数
        '/statistics/totalLoanCount',
        // 明天待还笔数
        '/statistics/tomorrowRepaymentCount',
        // 明天待还总金额
        '/statistics/tomorrowRepaymentAmount',
        // 平台总收益
        '/statistics/totalPlatformEarnings',
        // 供应商数量总览
        '/statistics/getSuppliersNumStatisticsInfo',
        // 按产品统计放款成功数量
        `/statistics/getAssetsStatisticsByProductInfo/${this.radio}`,
        // 按核心企业统计放款成功数量
        `/statistics/getAssetsStatisticsByCoreCompanyInfo/${this.radio2}`,
        // 所有借贷成功的总金额
        '/statistics/getTotalLoanSuccessAmount',
        // 所有借贷成功的总笔数
        '/statistics/getTotalLoanSuccessCount',
        // 运单总数
        '/statistics/totalTaskBillCount'
      ]
      const params = {
        tenantId: window.localStorage.getItem('tenantId'),
        userId: window.localStorage.getItem('platform'),
      }
      Promise.all(requestList.map((elem) => axios.post(elem, params))).then(
        (res) => {
          const map = [
            // 今日放款总笔数
            'todayLoanCount',
            // 今日放款总金额
            'todayLoanAmount',
            // 近7天放款总金额
            'last7DaysLoanAmount',
            // 近30天放款总金额
            'last30DaysLoanAmount',
            // 待初审订单
            'ordersPendingInitialReview',
            // 待复审订单
            'ordersPendingReReview',
            // 待放款订单
            'ordersPendingLoan',
            // 已结清订单
            'ordersSettled',
            // 已逾期订单
            'ordersOverdue',
            // 已展期订单
            'ordersExtended',
            // 初审驳回订单
            'ordersInitialReviewRejected',
            // 复审驳回订单
            'ordersRejectedSecondReview',
            // 放款失败订单
            'loanFailedOrders',
            // 在贷总笔数
            'totalLoanCount',
            // 明天待还笔数
            'tomorrowRepaymentCount',
            // 明天待还总金额
            'tomorrowRepaymentAmount',
            // 平台总收益
            'totalPlatformEarnings',
            // 供应商数量总览
            'getSuppliersNumStatisticsInfo',
            // 按产品统计放款成功数量
            'getAssetsStatisticsByProductInfo',
            // 按核心企业统计放款成功数量
            'getAssetsStatisticsByCoreCompanyInfo',
            // 所有借贷成功的总金额
            'getTotalLoanSuccessAmount',
            // 所有借贷成功的总笔数
            'getTotalLoanSuccessCount',
            // 运单总数
            'totalTaskBillCount'
          ]
          const responseData = {}
          map.forEach((elem, index) => {
            const { data } = res[index].data
            if(['todayLoanAmount','last7DaysLoanAmount','last30DaysLoanAmount','tomorrowRepaymentAmount','totalPlatformEarnings'].includes(elem)){
              responseData[elem] = data/100
            }else{
              responseData[elem] = data
            }
          })
          this.responseData = responseData
          this.setProductChart(responseData.getAssetsStatisticsByProductInfo)
          this.setEnterpriseChart(
            responseData.getAssetsStatisticsByCoreCompanyInfo
          )
        }
      )
    },
    setProductChart(data) {
      this.productChart = echarts.init(document.getElementById('product'))
      const option = {
        tooltip: {},
        grid: {
          right: '5%',
          left: '5%',
          top: '30',
          bottom: '30',
        },
        xAxis: {
          type: 'category',
          data: data.map((elem) => elem.productName),
        },
        yAxis: {
          type: 'value',
          name: '借贷成功数',
        },
        series: [
          {
            data: data.map((elem) => +elem.loanSuccessCount),
            type: 'bar',
            itemStyle: {
              color: '#1d90ff',
            },
            barMaxWidth: 60,
          },
        ],
      }
      this.productChart.setOption(option)
    },
    setEnterpriseChart(data) {
      if (!data.length) return
      this.enterpriseChart = echarts.init(document.getElementById('enterprise'))
      const option = {
        tooltip: {},
        grid: {
          right: '5%',
          left: '5%',
          top: '30',
          bottom: '30',
        },
        xAxis: {
          type: 'category',
          data: data.map((elem) => elem.profileName),
        },
        yAxis: {
          type: 'value',
          name: '借贷成功数',
        },
        series: [
          {
            data: data.map((elem) => +elem.loanSuccessCount),
            type: 'bar',
            itemStyle: {
              color: '#1d90ff',
            },
            barMaxWidth: 60,
          },
        ],
      }
      this.enterpriseChart.setOption(option)
    },
    productChartChange(value) {
      const params = {
        tenantId: window.localStorage.getItem('tenantId'),
        userId: window.localStorage.getItem('platform'),
      }
      axios
        .post(
          `/statistics/getAssetsStatisticsByProductInfo/${this.radio}`,
          params
        )
        .then((res) => {
          if (res.data.code === 200) {
            const { data } = res.data
            this.setProductChart(data)
          }
        })
    },
    enterpriseChartChange(value) {
      const params = {
        tenantId: window.localStorage.getItem('tenantId'),
        userId: window.localStorage.getItem('platform'),
      }
      axios
        .post(
          `/statistics/getAssetsStatisticsByCoreCompanyInfo/${this.radio2}`,
          params
        )
        .then((res) => {
          if (res.data.code === 200) {
            const { data } = res.data
            this.setEnterpriseChart(data)
          }
        })
    },
  },
}
</script>
<style scoped>
.system-board {
  background-color: #fff;
  /* height: calc(100vh - 136px); */
  padding: 24px;
}
.top-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  border: 1px solid #e2e3e4;
  border-radius: 10px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.top-left {
  font-size: 40px;
  margin-right: 16px;
}
.top-right .right-title {
  font-size: 14px;
  color: #666;
  padding-bottom: 8px;
}
.top-right .right-count {
  font-size: 24px;
}
.box {
  margin-top: 24px;
  border: 1px solid #e2e3e4;
  border-radius: 10px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.box-title {
  padding: 16px 24px;
  text-align: left;
  color: #1a1a1a;
  /* border-bottom: 1px solid #e2e3e4; */
}
.box-title2 {
  color: #1a1a1a;
  padding: 32px 24px 0;
  display: flex;
  justify-content: space-between;
}
.box-content {
  padding: 16px 24px;
  text-align: left;
}
>>> .el-descriptions__body {
  background-color: transparent;
}
>>> .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 24px;
}
>>> .el-descriptions-item__content {
  padding-left: 60px;
}
.middle-count {
  padding: 8px 0 16px;
  font-size: 24px;
  text-align: center;
}
.middle-count >>> .el-statistic .con .number {
  font-size: 24px;
  line-height: 28px;
}
.middle-title {
  text-align: center;
  font-size: 16px;
  padding-bottom: 12px;
  color: #666;
}
#product,
#enterprise {
  height: 400px;
}
.y-axis-title {
  text-align: left;
  font-size: 14px;
  padding-left: 24px;
  color: #666;
  line-height: 12px;
  height: 12px;
}
</style>

